"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.ts
var src_exports = {};
__export(src_exports, {
  ADDITIONAL_BASES: () => ADDITIONAL_BASES,
  ARB: () => ARB,
  ARB_ADDRESS: () => ARB_ADDRESS,
  Amount: () => Amount,
  BASES_TO_CHECK_TRADES_AGAINST: () => BASES_TO_CHECK_TRADES_AGAINST,
  CUSTOM_BASES: () => CUSTOM_BASES,
  DAI: () => DAI,
  DAI_ADDRESS: () => DAI_ADDRESS,
  DOT: () => DOT,
  DOT_ADDRESS: () => DOT_ADDRESS,
  FRAX: () => FRAX,
  FRAX_ADDRESS: () => FRAX_ADDRESS,
  KSM: () => KSM,
  KSM_ADDRESS: () => KSM_ADDRESS,
  LINK: () => LINK,
  LINK_ADDRESS: () => LINK_ADDRESS,
  Native: () => Native,
  Price: () => Price,
  Share: () => Share,
  Token: () => Token,
  UNI: () => UNI,
  UNI_ADDRESS: () => UNI_ADDRESS,
  USDC: () => USDC,
  USDC_ADDRESS: () => USDC_ADDRESS,
  USDT: () => USDT,
  USDT_ADDRESS: () => USDT_ADDRESS,
  WBTC: () => WBTC,
  WBTC_ADDRESS: () => WBTC_ADDRESS,
  WETH9: () => WETH9,
  WETH9_ADDRESS: () => WETH9_ADDRESS,
  WNATIVE: () => WNATIVE,
  WNATIVE_ADDRESS: () => WNATIVE_ADDRESS,
  XCAUSD: () => XCAUSD,
  XCAUSD_ADDRESS: () => XCAUSD_ADDRESS,
  ZLK: () => ZLK,
  ZLK_ADDRESS: () => ZLK_ADDRESS,
  getCurrencyCombinations: () => getCurrencyCombinations,
  tryParseAmount: () => tryParseAmount,
  useCurrencyCombinations: () => useCurrencyCombinations,
  useNativeCurrency: () => useNativeCurrency
});
module.exports = __toCommonJS(src_exports);

// src/Amount.ts
var import_math2 = require("@zenlink-interface/math");
var import_tiny_invariant2 = __toESM(require("tiny-invariant"));

// src/Share.ts
var import_math = require("@zenlink-interface/math");
var import_tiny_invariant = __toESM(require("tiny-invariant"));
var Share = class extends import_math.Fraction {
  constructor(currency, numerator, denominator) {
    super(numerator, denominator);
    (0, import_tiny_invariant.default)(import_math.JSBI.lessThanOrEqual(this.quotient, import_math.MAX_UINT128), "SHARE");
    this.currency = currency;
    this.scale = import_math.JSBI.exponentiate(import_math.JSBI.BigInt(10), import_math.JSBI.BigInt(currency.decimals));
  }
  static fromRawShare(currency, rawShare) {
    return new Share(currency, rawShare);
  }
  toAmount(rebase, roundUp = false) {
    if (import_math.JSBI.EQ(rebase.base, import_math.ZERO))
      return Amount.fromRawAmount(this.currency, this.quotient);
    const elastic = import_math.JSBI.divide(import_math.JSBI.multiply(this.quotient, rebase.elastic), rebase.base);
    if (roundUp && import_math.JSBI.LT(import_math.JSBI.divide(import_math.JSBI.multiply(elastic, rebase.base), rebase.elastic), this.quotient))
      return Amount.fromRawAmount(this.currency, import_math.JSBI.add(elastic, import_math.JSBI.BigInt(1)));
    return Amount.fromRawAmount(this.currency, elastic);
  }
  /**
   * Construct a currency share with a denominator that is not equal to 1
   * @param currency the currency
   * @param numerator the numerator of the fractional token share
   * @param denominator the denominator of the fractional token share
   */
  static fromFractionalShare(currency, numerator, denominator) {
    return new Share(currency, numerator, denominator);
  }
  add(other) {
    (0, import_tiny_invariant.default)(this.currency.equals(other.currency), "CURRENCY");
    const added = super.add(other);
    return Share.fromFractionalShare(this.currency, added.numerator, added.denominator);
  }
  subtract(other) {
    (0, import_tiny_invariant.default)(this.currency.equals(other.currency), "CURRENCY");
    const subtracted = super.subtract(other);
    return Share.fromFractionalShare(this.currency, subtracted.numerator, subtracted.denominator);
  }
  multiply(other) {
    const multiplied = super.multiply(other);
    return Share.fromFractionalShare(this.currency, multiplied.numerator, multiplied.denominator);
  }
  divide(other) {
    const divided = super.divide(other);
    return Share.fromFractionalShare(this.currency, divided.numerator, divided.denominator);
  }
  toSignificant(significantDigits = 6, format, rounding = import_math.Rounding.ROUND_DOWN) {
    return super.divide(this.scale).toSignificant(significantDigits, format, rounding);
  }
  toFixed(decimalPlaces = this.currency.decimals, format, rounding = import_math.Rounding.ROUND_DOWN) {
    (0, import_tiny_invariant.default)(decimalPlaces <= this.currency.decimals, "DECIMALS");
    return super.divide(this.scale).toFixed(decimalPlaces, format, rounding);
  }
  toExact(format = { groupSeparator: "" }) {
    import_math.Big.DP = this.currency.decimals;
    return new import_math.Big(this.quotient.toString()).div(this.scale.toString()).toFormat(format);
  }
};

// src/Amount.ts
var Amount = class extends import_math2.Fraction {
  constructor(currency, numerator, denominator) {
    super(numerator, denominator);
    (0, import_tiny_invariant2.default)(import_math2.JSBI.lessThanOrEqual(this.quotient, import_math2.MAX_UINT256), "AMOUNT");
    this.currency = currency;
    this.scale = import_math2.JSBI.exponentiate(import_math2.JSBI.BigInt(10), import_math2.JSBI.BigInt(currency.decimals));
  }
  /**
   * Returns a new currency amount instance from the unitless amount of token, i.e. the raw amount
   * @param currency the currency in the amount
   * @param rawAmount the raw token or ether amount
   */
  static fromRawAmount(currency, rawAmount) {
    return new Amount(currency, rawAmount);
  }
  static fromShare(currency, shares, rebase, roundUp = false) {
    if (import_math2.JSBI.EQ(rebase.base, import_math2.ZERO))
      return new Amount(currency, shares);
    const elastic = import_math2.JSBI.divide(import_math2.JSBI.multiply(import_math2.JSBI.BigInt(shares), rebase.elastic), rebase.base);
    if (roundUp && import_math2.JSBI.LT(import_math2.JSBI.divide(import_math2.JSBI.multiply(elastic, rebase.base), rebase.elastic), import_math2.JSBI.BigInt(shares)))
      return new Amount(currency, import_math2.JSBI.add(elastic, import_math2.JSBI.BigInt(1)));
    return new Amount(currency, elastic);
  }
  toShare(rebase, roundUp = false) {
    if (import_math2.JSBI.EQ(rebase.elastic, import_math2.ZERO))
      return Share.fromRawShare(this.currency, this.quotient);
    const base = import_math2.JSBI.divide(import_math2.JSBI.multiply(this.quotient, rebase.base), rebase.elastic);
    if (roundUp && import_math2.JSBI.LT(import_math2.JSBI.divide(import_math2.JSBI.multiply(base, rebase.elastic), rebase.base), this.quotient))
      return Share.fromRawShare(this.currency, import_math2.JSBI.add(base, import_math2.JSBI.BigInt(1)));
    return Share.fromRawShare(this.currency, base);
  }
  /**
   * Construct a currency amount with a denominator that is not equal to 1
   * @param currency the currency
   * @param numerator the numerator of the fractional token amount
   * @param denominator the denominator of the fractional token amount
   */
  static fromFractionalAmount(currency, numerator, denominator) {
    return new Amount(currency, numerator, denominator);
  }
  add(other) {
    (0, import_tiny_invariant2.default)(this.currency.equals(other.currency), "CURRENCY");
    const added = super.add(other);
    return Amount.fromFractionalAmount(this.currency, added.numerator, added.denominator);
  }
  subtract(other) {
    (0, import_tiny_invariant2.default)(this.currency.equals(other.currency), "CURRENCY");
    const subtracted = super.subtract(other);
    return Amount.fromFractionalAmount(this.currency, subtracted.numerator, subtracted.denominator);
  }
  multiply(other) {
    const multiplied = super.multiply(other);
    return Amount.fromFractionalAmount(this.currency, multiplied.numerator, multiplied.denominator);
  }
  divide(other) {
    const divided = super.divide(other);
    return Amount.fromFractionalAmount(this.currency, divided.numerator, divided.denominator);
  }
  toSignificant(significantDigits = 6, format, rounding = import_math2.Rounding.ROUND_DOWN) {
    return super.divide(this.scale).toSignificant(significantDigits, format, rounding);
  }
  toFixed(decimalPlaces = this.currency.decimals, format, rounding = import_math2.Rounding.ROUND_DOWN) {
    (0, import_tiny_invariant2.default)(decimalPlaces <= this.currency.decimals, "DECIMALS");
    return super.divide(this.scale).toFixed(decimalPlaces, format, rounding);
  }
  toHex() {
    return `0x${this.quotient.toString(16)}`;
  }
  toExact(format = { groupSeparator: "" }) {
    import_math2.Big.DP = this.currency.decimals;
    return new import_math2.Big(this.quotient.toString()).div(this.scale.toString()).toFormat(format);
  }
  get wrapped() {
    if (this.currency.isToken)
      return this;
    return Amount.fromFractionalAmount(this.currency.wrapped, this.numerator, this.denominator);
  }
};

// src/constants/tokenAddresses.ts
var import_chain = require("@zenlink-interface/chain");
var WNATIVE_ADDRESS = {
  // [ParachainId.MOONRIVER]: '0x98878B06940aE243284CA214f92Bb71a2b032B8A',
  // [ParachainId.MOONBEAM]: '0xAcc15dC74880C9944775448304B263D191c6077F',
  // [ParachainId.ASTAR]: '0xAeaaf0e2c81Af264101B9129C00F4440cCF0F720',
  // [ParachainId.ARBITRUM_ONE]: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
  // [ParachainId.BIFROST_KUSAMA]: '2001-0-0',
  // [ParachainId.BIFROST_POLKADOT]: '2030-0-0',
  [import_chain.ParachainId.CALAMARI_KUSAMA]: "2084-0-0",
  [import_chain.ParachainId.MANTA_STAGING]: "2104-0-0",
  [import_chain.ParachainId.MANTA_POLKADOT]: "2104-0-0"
};
var WETH9_ADDRESS = {
  // [ParachainId.ARBITRUM_ONE]: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
};
var WBTC_ADDRESS = {
  // [ParachainId.ARBITRUM_ONE]: '0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f',
};
var UNI_ADDRESS = {
  // [ParachainId.ARBITRUM_ONE]: '0xFa7F8980b0f1E64A2062791cc3b0871572f1F7f0',
};
var ZLK_ADDRESS = {
  // [ParachainId.ASTAR]: '0x998082c488e548820f970df5173bd2061ce90635',
  // [ParachainId.MOONRIVER]: '0x0f47ba9d9bde3442b42175e51d6a367928a1173b',
  // [ParachainId.MOONBEAM]: '0x3fd9b6c9a24e09f67b7b706d72864aebb439100c',
  // [ParachainId.BIFROST_KUSAMA]: '2001-2-519',
  // [ParachainId.BIFROST_POLKADOT]: '2030-2-519',
};
var USDC_ADDRESS = {
  // [ParachainId.MOONRIVER]: '0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D',
  // [ParachainId.MOONBEAM]: '0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b',
  // [ParachainId.ASTAR]: '0x6a2d262D56735DbA19Dd70682B39F6bE9a931D98',
  // [ParachainId.ARBITRUM_ONE]: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
};
var USDT_ADDRESS = {
  // [ParachainId.MOONRIVER]: '0xB44a9B6905aF7c801311e8F4E76932ee959c663C',
  // [ParachainId.MOONBEAM]: '0xeFAeeE334F0Fd1712f9a8cc375f427D9Cdd40d73',
  // [ParachainId.ASTAR]: '0xffffffff000000000000000000000001000007c0',
  // [ParachainId.ARBITRUM_ONE]: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
  // [ParachainId.BIFROST_KUSAMA]: '2001-2-2048',
  // [ParachainId.CALAMARI_KUSAMA]: '2084-2-14',
  // [ParachainId.MANTA_STAGING]: '2104-2-9',
  [import_chain.ParachainId.MANTA_POLKADOT]: "2104-2-9"
};
var DAI_ADDRESS = {
  // [ParachainId.ASTAR]: '0x6De33698e9e9b787e09d3Bd7771ef63557E148bb',
  // [ParachainId.ARBITRUM_ONE]: '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
};
var FRAX_ADDRESS = {
  // [ParachainId.MOONRIVER]: '0x1A93B23281CC1CDE4C4741353F3064709A16197d',
  // [ParachainId.MOONBEAM]: '0x322E86852e492a7Ee17f28a78c663da38FB33bfb',
  // [ParachainId.ARBITRUM_ONE]: '0x17FC002b466eEc40DaE837Fc4bE5c67993ddBd6F',
};
var XCAUSD_ADDRESS = {
  // [ParachainId.MOONRIVER]: '0xFfFffFFfa1B026a00FbAA67c86D5d1d5BF8D8228',
  // [ParachainId.MOONBEAM]: '0xfFfFFFFF52C56A9257bB97f4B2b6F7B2D624ecda',
  // [ParachainId.BIFROST_KUSAMA]: '2001-2-770',
};
var KSM_ADDRESS = {
  // [ParachainId.BIFROST_KUSAMA]: '2001-2-516',
};
var DOT_ADDRESS = {
  // [ParachainId.ASTAR]: '0xffffffffffffffffffffffffffffffffffffffff',
  [import_chain.ParachainId.MANTA_STAGING]: "2104-2-8",
  [import_chain.ParachainId.MANTA_POLKADOT]: "2104-2-8"
};
var LINK_ADDRESS = {
  // [ParachainId.ARBITRUM_ONE]: '0xf97f4df75117a78c1A5a0DBb814Af92458539FB4',
};
var ARB_ADDRESS = {
  // [ParachainId.ARBITRUM_ONE]: '0x912CE59144191C1204E64559FE8253a0e49E6548',
};

// src/constants/tokens.ts
var import_chain2 = require("@zenlink-interface/chain");

// src/Token.ts
var import_address = require("@ethersproject/address");
var import_format = require("@zenlink-interface/format");
var import_tiny_invariant4 = __toESM(require("tiny-invariant"));

// src/Currency.ts
var import_tiny_invariant3 = __toESM(require("tiny-invariant"));
var Currency = class {
  /**
   * Constructs an instance of the abstract class `Currency`.
   * @param chainId the chain ID on which this currency resides
   * @param decimals decimals of the currency
   * @param symbol symbol of the currency
   * @param name of the currency
   */
  constructor({
    chainId,
    decimals,
    symbol,
    name
  }) {
    (0, import_tiny_invariant3.default)(Number.isSafeInteger(Number(chainId)), "CHAIN_ID");
    (0, import_tiny_invariant3.default)(Number(decimals) >= 0 && Number(decimals) < 255 && Number.isInteger(Number(decimals)), "DECIMALS");
    this.chainId = Number(chainId);
    this.decimals = Number(decimals);
    this.symbol = symbol;
    this.name = name;
  }
};

// src/Token.ts
var Token = class extends Currency {
  constructor(token) {
    super(token);
    this.isNative = false;
    this.isToken = true;
    try {
      if ((0, import_format.isZenlinkAddress)(token.address))
        this.address = token.address;
      else
        this.address = (0, import_address.getAddress)(token.address);
    } catch (e) {
      throw new Error(`${token.address} is not a valid address`);
    }
  }
  /**
   * Returns true if the two tokens are equivalent, i.e. have the same chainId and address.
   * @param other other token to compare
   */
  equals(other) {
    return other.isToken && this.chainId === other.chainId && this.address === other.address;
  }
  /**
   * Returns true if the address of this token sorts before the address of the other token
   * @param other other token to compare
   * @throws if the tokens have the same address
   * @throws if the tokens are on different chains
   */
  sortsBefore(other) {
    (0, import_tiny_invariant4.default)(this.chainId === other.chainId, "CHAIN_IDS");
    (0, import_tiny_invariant4.default)(this.address !== other.address, "ADDRESSES");
    if (!(0, import_address.isAddress)(this.address) && (0, import_format.isZenlinkAddress)(this.address)) {
      const { chainId, assetType, assetIndex } = (0, import_format.addressToZenlinkAssetId)(this.address);
      const otherTokenAssetId = (0, import_format.addressToZenlinkAssetId)(other.address);
      return chainId < otherTokenAssetId.chainId || assetType < otherTokenAssetId.assetType || assetIndex < otherTokenAssetId.assetIndex;
    }
    return this.address.toLowerCase() < other.address.toLowerCase();
  }
  /**
   * Return this token, which does not need to be wrapped
   */
  get wrapped() {
    return this;
  }
};

// src/addressMapToTokenMap.ts
function addressMapToTokenMap({ decimals, symbol, name }, map) {
  return Object.fromEntries(
    Object.entries(map).map(([chainId, address]) => [
      chainId,
      new Token({
        chainId,
        address,
        decimals,
        symbol,
        name
      })
    ])
  );
}

// src/constants/tokens.ts
var WNATIVE = {
  // [ParachainId.MOONRIVER]: new Token({
  //   chainId: ParachainId.MOONRIVER,
  //   address: WNATIVE_ADDRESS[ParachainId.MOONRIVER],
  //   decimals: 18,
  //   symbol: 'WMOVR',
  //   name: 'Wrapped Moonriver',
  // }),
  // [ParachainId.MOONBEAM]: new Token({
  //   chainId: ParachainId.MOONBEAM,
  //   address: WNATIVE_ADDRESS[ParachainId.MOONBEAM],
  //   decimals: 18,
  //   symbol: 'WGLMR',
  //   name: 'Wrapped Glimmer',
  // }),
  // [ParachainId.ASTAR]: new Token({
  //   chainId: ParachainId.ASTAR,
  //   address: WNATIVE_ADDRESS[ParachainId.ASTAR],
  //   decimals: 18,
  //   symbol: 'WASTR',
  //   name: 'Wrapped Astar',
  // }),
  // [ParachainId.ARBITRUM_ONE]: new Token({
  //   chainId: ParachainId.ARBITRUM_ONE,
  //   address: WNATIVE_ADDRESS[ParachainId.ARBITRUM_ONE],
  //   decimals: 18,
  //   name: 'Wrapped Ether',
  //   symbol: 'WETH',
  // }),
  // [ParachainId.BIFROST_KUSAMA]: new Token({
  //   chainId: ParachainId.BIFROST_KUSAMA,
  //   address: WNATIVE_ADDRESS[ParachainId.BIFROST_KUSAMA],
  //   decimals: 12,
  //   symbol: 'BNC',
  //   name: 'Bifrost',
  // }),
  // [ParachainId.BIFROST_POLKADOT]: new Token({
  //   chainId: ParachainId.BIFROST_POLKADOT,
  //   address: WNATIVE_ADDRESS[ParachainId.BIFROST_POLKADOT],
  //   decimals: 12,
  //   symbol: 'BNC',
  //   name: 'Bifrost',
  // }),
  // [ParachainId.CALAMARI_KUSAMA]: new Token({
  //   chainId: ParachainId.CALAMARI_KUSAMA,
  //   address: WNATIVE_ADDRESS[ParachainId.CALAMARI_KUSAMA],
  //   decimals: 12,
  //   symbol: 'KMA',
  //   name: 'Calamari',
  // }),
  [import_chain2.ParachainId.MANTA_STAGING]: new Token({
    chainId: import_chain2.ParachainId.MANTA_STAGING,
    address: WNATIVE_ADDRESS[import_chain2.ParachainId.MANTA_STAGING],
    decimals: 18,
    symbol: "MANTA",
    name: "Manta"
  }),
  [import_chain2.ParachainId.MANTA_POLKADOT]: new Token({
    chainId: import_chain2.ParachainId.MANTA_POLKADOT,
    address: WNATIVE_ADDRESS[import_chain2.ParachainId.MANTA_POLKADOT],
    decimals: 18,
    symbol: "MANTA",
    name: "Manta"
  })
};
var WETH9 = addressMapToTokenMap(
  {
    decimals: 18,
    symbol: "WETH",
    name: "Wrapped Ether"
  },
  WETH9_ADDRESS
);
var WBTC = addressMapToTokenMap(
  {
    decimals: 8,
    symbol: "WBTC",
    name: "Wrapped BTC"
  },
  WBTC_ADDRESS
);
var UNI = addressMapToTokenMap(
  {
    symbol: "UNI",
    decimals: 18,
    name: "Uniswap"
  },
  UNI_ADDRESS
);
var ZLK = __spreadValues({}, addressMapToTokenMap(
  {
    decimals: 18,
    symbol: "ZLK",
    name: "Zenlink Network Token"
  },
  ZLK_ADDRESS
));
var USDC = __spreadValues({}, addressMapToTokenMap(
  {
    decimals: 6,
    symbol: "USDC",
    name: "USD Coin"
  },
  USDC_ADDRESS
));
var USDT = addressMapToTokenMap(
  {
    decimals: 6,
    symbol: "USDT",
    name: "Tether USD"
  },
  USDT_ADDRESS
);
var DAI = addressMapToTokenMap(
  {
    decimals: 18,
    symbol: "DAI",
    name: "Dai Stablecoin"
  },
  DAI_ADDRESS
);
var FRAX = addressMapToTokenMap(
  {
    decimals: 18,
    symbol: "FRAX",
    name: "Frax"
  },
  FRAX_ADDRESS
);
var XCAUSD = addressMapToTokenMap(
  {
    decimals: 12,
    symbol: "xcaUSD",
    name: "Acala Dollar"
  },
  XCAUSD_ADDRESS
);
var KSM = addressMapToTokenMap(
  {
    decimals: 12,
    symbol: "KSM",
    name: "Kusama"
  },
  KSM_ADDRESS
);
var DOT = addressMapToTokenMap(
  {
    decimals: 10,
    symbol: "DOT",
    name: "Polkadot"
  },
  DOT_ADDRESS
);
var LINK = addressMapToTokenMap(
  {
    decimals: 18,
    symbol: "LINK",
    name: "ChainLink Token"
  },
  LINK_ADDRESS
);
var ARB = addressMapToTokenMap(
  {
    decimals: 18,
    symbol: "ARB",
    name: "Arbitrum"
  },
  ARB_ADDRESS
);

// src/getCurrencyCombinations.ts
var import_chain3 = require("@zenlink-interface/chain");
var import_lodash = __toESM(require("lodash.flatmap"));
var BASES_TO_CHECK_TRADES_AGAINST = {
  // [ParachainId.MOONRIVER]: [
  //   WNATIVE[ParachainId.MOONRIVER],
  //   USDC[ParachainId.MOONRIVER],
  //   FRAX[ParachainId.MOONRIVER],
  //   XCAUSD[ParachainId.MOONRIVER],
  //   USDT[ParachainId.MOONRIVER],
  //   ZLK[ParachainId.MOONRIVER],
  // ],
  // [ParachainId.MOONBEAM]: [
  //   WNATIVE[ParachainId.MOONBEAM],
  //   USDC[ParachainId.MOONBEAM],
  //   FRAX[ParachainId.MOONBEAM],
  //   XCAUSD[ParachainId.MOONBEAM],
  //   USDT[ParachainId.MOONBEAM],
  //   ZLK[ParachainId.MOONBEAM],
  //   new Token({
  //     chainId: ParachainId.MOONBEAM,
  //     address: '0xc234a67a4f840e61ade794be47de455361b52413',
  //     decimals: 18,
  //     symbol: 'madDAI',
  //     name: 'Dai Stablecoin',
  //   }),
  //   new Token({
  //     chainId: ParachainId.MOONBEAM,
  //     address: '0x8f552a71efe5eefc207bf75485b356a0b3f01ec9',
  //     decimals: 6,
  //     symbol: 'madUSDC',
  //     name: 'USD Coin',
  //   }),
  //   new Token({
  //     chainId: ParachainId.MOONBEAM,
  //     address: '0x8e70cd5b4ff3f62659049e74b6649c6603a0e594',
  //     decimals: 6,
  //     symbol: 'madUSDT',
  //     name: 'Tether USD',
  //   }),
  //   new Token({
  //     chainId: ParachainId.MOONBEAM,
  //     address: '0x81ecac0d6be0550a00ff064a4f9dd2400585fe9c',
  //     decimals: 6,
  //     symbol: 'ceUSDT',
  //     name: 'Tether USD (Celer)',
  //   }),
  //   new Token({
  //     chainId: ParachainId.MOONBEAM,
  //     address: '0x6a2d262d56735dba19dd70682b39f6be9a931d98',
  //     decimals: 6,
  //     symbol: 'ceUSDC',
  //     name: 'USD Coin (Celer)',
  //   }),
  //   new Token({
  //     chainId: ParachainId.MOONBEAM,
  //     address: '0xa649325aa7c5093d12d6f98eb4378deae68ce23f',
  //     decimals: 18,
  //     symbol: 'anyBUSD',
  //     name: 'Binance-Peg BUSD Token',
  //   }),
  //   new Token({
  //     chainId: ParachainId.MOONBEAM,
  //     address: '0x765277eebeca2e31912c9946eae1021199b39c61',
  //     decimals: 18,
  //     symbol: 'anyDAI',
  //     name: 'Dai Stablecoin',
  //   }),
  // ],
  // [ParachainId.ASTAR]: [
  //   WNATIVE[ParachainId.ASTAR],
  //   USDC[ParachainId.ASTAR],
  //   DAI[ParachainId.ASTAR],
  //   USDT[ParachainId.ASTAR],
  //   DOT[ParachainId.ASTAR],
  //   ZLK[ParachainId.ASTAR],
  //   new Token({
  //     chainId: ParachainId.ASTAR,
  //     address: '0x4Bf769b05E832FCdc9053fFFBC78Ca889aCb5E1E',
  //     decimals: 18,
  //     symbol: 'BUSD',
  //     name: 'Binance USD',
  //   }),
  //   new Token({
  //     chainId: ParachainId.ASTAR,
  //     address: '0x733ebcC6DF85f8266349DEFD0980f8Ced9B45f35',
  //     decimals: 18,
  //     symbol: 'BAI',
  //     name: 'BAI Stablecoin',
  //   }),
  // ],
  // [ParachainId.BIFROST_KUSAMA]: [
  //   WNATIVE[ParachainId.BIFROST_KUSAMA],
  //   USDT[ParachainId.BIFROST_KUSAMA],
  //   XCAUSD[ParachainId.BIFROST_KUSAMA],
  //   KSM[ParachainId.BIFROST_KUSAMA],
  //   ZLK[ParachainId.BIFROST_KUSAMA],
  // ],
  // [ParachainId.BIFROST_POLKADOT]: [
  //   WNATIVE[ParachainId.BIFROST_POLKADOT],
  //   DOT[ParachainId.BIFROST_POLKADOT],
  //   new Token({
  //     chainId: ParachainId.BIFROST_POLKADOT,
  //     address: '2030-2-2304',
  //     decimals: 10,
  //     symbol: 'vDOT',
  //     name: 'Voucher DOT',
  //   }),
  //   new Token({
  //     chainId: ParachainId.BIFROST_POLKADOT,
  //     address: '2030-2-2560',
  //     decimals: 10,
  //     symbol: 'vsDOT',
  //     name: 'Voucher Slot DOT',
  //   }),
  // ],
  // [ParachainId.CALAMARI_KUSAMA]: [
  //   WNATIVE[ParachainId.CALAMARI_KUSAMA],
  // ],
  [import_chain3.ParachainId.MANTA_STAGING]: [
    WNATIVE[import_chain3.ParachainId.MANTA_STAGING]
  ],
  [import_chain3.ParachainId.MANTA_POLKADOT]: [
    WNATIVE[import_chain3.ParachainId.MANTA_POLKADOT]
  ]
};
var ADDITIONAL_BASES = {
  // [ParachainId.MOONRIVER]: {
  //   '0x3b25BC1dC591D24d60560d0135D6750A561D4764': [
  //     new Token({
  //       chainId: ParachainId.MOONRIVER,
  //       address: '0x639A647fbe20b6c8ac19E48E2de44ea792c62c5C',
  //       decimals: 18,
  //       symbol: 'ETH',
  //       name: 'BAI Ethereum',
  //     }),
  //   ],
  //   '0x639A647fbe20b6c8ac19E48E2de44ea792c62c5C': [
  //     new Token({
  //       chainId: ParachainId.MOONRIVER,
  //       address: '0x3b25BC1dC591D24d60560d0135D6750A561D4764',
  //       decimals: 18,
  //       symbol: 'vETH',
  //       name: 'Voucher Ethereum',
  //     }),
  //   ],
  // },
};
var CUSTOM_BASES = {};
function getCurrencyCombinations(chainId, currencyA, currencyB) {
  var _a, _b, _c, _d;
  const [tokenA, tokenB] = chainId ? [currencyA == null ? void 0 : currencyA.wrapped, currencyB == null ? void 0 : currencyB.wrapped] : [void 0, void 0];
  const common = chainId in BASES_TO_CHECK_TRADES_AGAINST ? BASES_TO_CHECK_TRADES_AGAINST[chainId] : [];
  const additionalA = tokenA ? (_b = (_a = ADDITIONAL_BASES[chainId]) == null ? void 0 : _a[tokenA.address]) != null ? _b : [] : [];
  const additionalB = tokenB ? (_d = (_c = ADDITIONAL_BASES[chainId]) == null ? void 0 : _c[tokenB.address]) != null ? _d : [] : [];
  const bases = [...common, ...additionalA, ...additionalB];
  const basePairs = (0, import_lodash.default)(
    bases,
    (base) => bases.map((otherBase) => [base, otherBase])
  );
  if (!tokenA || !tokenB)
    return [];
  const cacheMap = {};
  return [
    // the direct pair
    [tokenA, tokenB],
    // token A against all bases
    ...bases.map((base) => [tokenA, base]),
    // token B against all bases
    ...bases.map((base) => [tokenB, base]),
    // each base against all bases
    ...basePairs
  ].filter((tokens) => Boolean(tokens[0] && tokens[1])).filter(([t0, t1]) => t0.address !== t1.address && t0.chainId === t1.chainId).filter(([tokenA2, tokenB2]) => {
    const cacheKey = tokenA2.sortsBefore(tokenB2) ? `${tokenA2.address}${tokenB2.address}` : `${tokenB2.address}${tokenA2.address}`;
    if (cacheMap[cacheKey])
      return false;
    return cacheMap[cacheKey] = true;
  }).filter(([tokenA2, tokenB2]) => {
    if (!chainId)
      return true;
    const customBases = CUSTOM_BASES[chainId];
    const customBasesA = customBases == null ? void 0 : customBases[tokenA2.address];
    const customBasesB = customBases == null ? void 0 : customBases[tokenB2.address];
    if (!customBasesA && !customBasesB)
      return true;
    if (customBasesA && !customBasesA.find((base) => tokenB2.equals(base)))
      return false;
    if (customBasesB && !customBasesB.find((base) => tokenA2.equals(base)))
      return false;
    return true;
  });
}

// src/Native.ts
var import_chain4 = __toESM(require("@zenlink-interface/chain"));
var import_tiny_invariant5 = __toESM(require("tiny-invariant"));
var _Native = class extends Currency {
  constructor(native) {
    super(native);
    this.isNative = true;
    this.isToken = false;
    this.symbol = native.symbol;
    this.name = native.name;
  }
  get wrapped() {
    const wnative = WNATIVE[this.chainId];
    (0, import_tiny_invariant5.default)(!!wnative, "WRAPPED");
    return wnative;
  }
  static onChain(chainId) {
    if (chainId in this.cache)
      return this.cache[chainId];
    (0, import_tiny_invariant5.default)(!!(chainId in import_chain4.default), "CHAINS");
    const { nativeCurrency } = import_chain4.default[chainId];
    (0, import_tiny_invariant5.default)(!!nativeCurrency, "NATIVE_CURRENCY");
    const { decimals, name, symbol } = nativeCurrency;
    return this.cache[chainId] = new _Native({ chainId, decimals, name, symbol });
  }
  equals(other) {
    return other.isNative && other.chainId === this.chainId;
  }
};
var Native = _Native;
Native.cache = {};

// src/Price.ts
var import_math3 = require("@zenlink-interface/math");
var import_tiny_invariant6 = __toESM(require("tiny-invariant"));
var Price = class extends import_math3.Fraction {
  // used to adjust the raw fraction w/r/t the decimals of the {base,quote}Token
  /**
   * Construct a price, either with the base and quote currency amount, or the
   * @param args
   */
  constructor(...args) {
    let baseCurrency, quoteCurrency, denominator, numerator;
    if (args.length === 4) {
      [baseCurrency, quoteCurrency, denominator, numerator] = args;
    } else {
      const result = args[0].quoteAmount.divide(args[0].baseAmount);
      [baseCurrency, quoteCurrency, denominator, numerator] = [
        args[0].baseAmount.currency,
        args[0].quoteAmount.currency,
        result.denominator,
        result.numerator
      ];
    }
    super(numerator, denominator);
    this.baseCurrency = baseCurrency;
    this.quoteCurrency = quoteCurrency;
    this.scalar = new import_math3.Fraction(
      import_math3.JSBI.exponentiate(import_math3.JSBI.BigInt(10), import_math3.JSBI.BigInt(baseCurrency.decimals)),
      import_math3.JSBI.exponentiate(import_math3.JSBI.BigInt(10), import_math3.JSBI.BigInt(quoteCurrency.decimals))
    );
  }
  /**
   * Flip the price, switching the base and quote currency
   */
  invert() {
    return new Price(this.quoteCurrency, this.baseCurrency, this.numerator, this.denominator);
  }
  /**
   * Multiply the price by another price, returning a new price. The other price must have the same base currency as this price's quote currency
   * @param other the other price
   */
  multiply(other) {
    (0, import_tiny_invariant6.default)(this.quoteCurrency.equals(other.baseCurrency), "TOKEN");
    const fraction = super.multiply(other);
    return new Price(this.baseCurrency, other.quoteCurrency, fraction.denominator, fraction.numerator);
  }
  /**
   * Return the amount of quote currency corresponding to a given amount of the base currency
   * @param currencyAmount the amount of base currency to quote against the price
   */
  quote(currencyAmount) {
    (0, import_tiny_invariant6.default)(currencyAmount.currency.equals(this.baseCurrency), "TOKEN");
    const result = super.multiply(currencyAmount);
    return Amount.fromFractionalAmount(this.quoteCurrency, result.numerator, result.denominator);
  }
  /**
   * Get the value scaled by decimals for formatting
   * @private
   */
  get adjustedForDecimals() {
    return super.multiply(this.scalar);
  }
  toSignificant(significantDigits = 6, format, rounding) {
    return this.adjustedForDecimals.toSignificant(significantDigits, format, rounding);
  }
  toFixed(decimalPlaces = 4, format, rounding) {
    return this.adjustedForDecimals.toFixed(decimalPlaces, format, rounding);
  }
};

// src/tryParseAmount.ts
var import_units = require("@ethersproject/units");
var import_math4 = require("@zenlink-interface/math");
function tryParseAmount(value, currency) {
  if (!value || !currency)
    return void 0;
  try {
    const typedValueParsed = (0, import_units.parseUnits)(value, currency.decimals).toString();
    if (typedValueParsed !== "0")
      return Amount.fromRawAmount(currency, import_math4.JSBI.BigInt(typedValueParsed));
  } catch (error) {
    console.debug(`Failed to parse input amount: "${value}"`, error);
  }
  return void 0;
}

// src/useCurrencyCombinations.ts
var import_react = require("react");
function useCurrencyCombinations(chainId, currencyA, currencyB) {
  return (0, import_react.useMemo)(
    () => chainId && currencyA && currencyB ? getCurrencyCombinations(chainId, currencyA, currencyB) : [],
    [chainId, currencyA, currencyB]
  );
}

// src/useNativeCurrency.ts
var import_chain5 = require("@zenlink-interface/chain");
var import_react2 = require("react");
function useNativeCurrency({ chainId = import_chain5.ParachainId.MANTA_POLKADOT }) {
  return (0, import_react2.useMemo)(() => Native.onChain(chainId), [chainId]);
}
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  ADDITIONAL_BASES,
  ARB,
  ARB_ADDRESS,
  Amount,
  BASES_TO_CHECK_TRADES_AGAINST,
  CUSTOM_BASES,
  DAI,
  DAI_ADDRESS,
  DOT,
  DOT_ADDRESS,
  FRAX,
  FRAX_ADDRESS,
  KSM,
  KSM_ADDRESS,
  LINK,
  LINK_ADDRESS,
  Native,
  Price,
  Share,
  Token,
  UNI,
  UNI_ADDRESS,
  USDC,
  USDC_ADDRESS,
  USDT,
  USDT_ADDRESS,
  WBTC,
  WBTC_ADDRESS,
  WETH9,
  WETH9_ADDRESS,
  WNATIVE,
  WNATIVE_ADDRESS,
  XCAUSD,
  XCAUSD_ADDRESS,
  ZLK,
  ZLK_ADDRESS,
  getCurrencyCombinations,
  tryParseAmount,
  useCurrencyCombinations,
  useNativeCurrency
});
