import * as React from 'react'

export const MantaDEXIcon = (props: React.ComponentProps<'svg'>) => (
  <svg
    width="34"
    height="38"
    viewBox="0 0 34 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3651 2.28926L16.2148 0.173066H16.2092C16.6313 -0.0576887 17.1434 -0.0576887 17.5655 0.173066L21.4152 2.28926L20.0588 4.7544L16.8902 3.00966L13.7215 4.7544L12.3651 2.28926ZM5.92972 8.96555L5.93209 8.96987V8.96424L5.92972 8.96555ZM4.5757 6.50473L5.92972 8.96555L4.18736 9.92666L6.02777 10.9397L4.67138 13.4049L2.81409 12.3862V14.6599H0V9.85349C0 9.34133 0.27578 8.86856 0.726034 8.62092L4.5757 6.50473ZM33.043 8.62092L29.1934 6.50473L27.837 8.96987L29.593 9.93229L27.7582 10.9397L29.1146 13.4049L30.9606 12.3918V14.6599H33.7747V9.84786C33.7747 9.3357 33.4933 8.86293 33.0487 8.61529L33.043 8.62092ZM29.1314 24.3348L30.9549 25.3366V23.1079H33.769V27.9143C33.769 28.4209 33.4932 28.8936 33.0486 29.1413L29.199 31.2969L27.8257 28.843L29.6436 27.8243L27.775 26.8L29.1314 24.3348ZM16.8902 34.9889L13.7215 33.2442L12.3651 35.7093L16.2148 37.8255C16.4287 37.9437 16.6594 38 16.8902 38C17.1209 38 17.3573 37.9437 17.5655 37.8255L21.4152 35.7093L20.0588 33.2442L16.8902 34.9889ZM2.81409 25.3535L4.67138 24.3348L6.02777 26.8L4.15922 27.8243L5.93209 28.798L4.5757 31.2631L0.726034 29.1469C0.281408 28.8993 0 28.4265 0 27.9143V23.1079H2.81409V25.3535Z"
      fill="url(#paint0_linear_266_42)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_266_42"
        x1="16.8873"
        y1="0"
        x2="16.8873"
        y2="38"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A495FF" />
        <stop offset="1" stopColor="#524793" />
      </linearGradient>
    </defs>
  </svg>
)
